<template>
  <div class="container">
    <pcPublicHeader />
    <div class="public-content">
      <div class="page-title">我的账户</div>
      <pcBalanceHeader pageKey="balance" />
      <div class="content-item">
        <div class="balance-item">账户余额 <span>¥{{ Math.round(userInfo.Balance * 100000) / 100000 }}</span></div>
        <div class="recharge-item">
          <div class="title-item">充值金额：</div>
          <div class="input-item">
            <div class="input">
              <input v-model="money" :type="selectedObj.Id != -1 ? 'number' : 'text'" :placeholder="selectedObj && selectedObj.Id == -1 ? '请输入充值卡密' : '请输入充值金额'" />
            </div>
            <div v-if="selectedObj && selectedObj.Id != -1" class="tip">{{ ('充值金额' + (selectedObj.Min ? ' 最低' + selectedObj.Min : '') + (selectedObj.Max ? ' 最高' + selectedObj.Max : '')) }}</div>
          </div>
          <div v-if="czTip2" class="cz-tip">{{ czTip2 }}</div>
          <div class="title-item">支付方式：</div>
          <div class="type-list-item">
            <div class="item" :class="selectedObj && selectedObj.Id == item.Id ? 'active' : ''" v-for="(item, index) in paymentList" :key="index" @click="handleChoosePayment(item)">
              <div class="icon img-cover">
                <img v-if="item.Paytype == 1" src="../../assets/images/alipay-icon.png" />
                <img v-if="item.Paytype == 2" src="../../assets/images/qq-icon.png" />
                <img v-if="item.Paytype == 3" src="../../assets/images/wechat-icon.png" />
              </div>
              <div class="name">{{ item.Payname }} <span v-if="item.Sxf">({{ item.Sxf }}%手续费)</span></div>
            </div>
            <div v-if="showKm" class="item" :class="selectedObj && selectedObj.Id == -1 ? 'active' : ''" @click="handleChoosePayment({Id: -1})">
              <div class="icon img-cover"><img src="../../assets/images/km-icon.png" /></div>
              <div class="name">卡密支付</div>
            </div>
          </div>
        </div>
        <div class="order-item">
          <div class="title-item">订单详情：</div>
          <div v-if="selectedObj && selectedObj.Id == -1" class="item">充值方式： <span>卡密支付</span></div>
          <template v-else>
            <div class="item">充值金额： <span>{{ money }}</span></div>
            <div class="item">充值方式： <span>{{ selectedObj.Payname }}</span></div>
          </template>
          <div class="item">充值时间： <span>{{ $TOOL.dateFormat(new Date().getTime(), 'yyyy-MM-dd') }}</span></div>
        </div>
        <div class="btn-item">
          <div class="cz-tip">{{ czTip ? czTip : '有问题请联系客服' }}</div>
          <div class="btn" @click="handleSubmit">
            <van-loading v-if="submiting" color="#ffffff" />
            <span v-else>充值</span>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="showQRCode" width="520px" destroy-on-close @closed="handleClosePopup">
      <template #title>
        <div class="qrcode-popup-title">
          <img class="icon" v-if="selectedObj.Paytype == 1" src="../../assets/images/alipay-icon.png" />
           <img class="icon" v-if="selectedObj.Paytype == 2" src="../../assets/images/qq-icon.png" />
          <img class="icon" v-if="selectedObj.Paytype == 3" src="../../assets/images/wechat-icon.png" />
          {{ selectedObj.Paytype == 1 ? '支付宝扫码支付' : '' }}
          {{ selectedObj.Paytype == 2 ? 'QQ扫码支付' : '' }}
          {{ selectedObj.Paytype == 3 ? '微信扫码支付' : '' }}
        </div>
      </template>
      <div class="qrcode-popup-content">
        <div class="img img-contain" ref="qrcode"></div>
        <div class="tip">请使用{{ selectedObj.Paytype == 1 ? '支付宝' : '' }}{{ selectedObj.Paytype == 2 ? 'QQ' : '' }}{{ selectedObj.Paytype == 3 ? '微信' : '' }}扫描二维码支付</div>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="sdPayPopup.show" width="520px" destroy-on-close @closed="handleCloseSdPayPopup">
      <template #title>
        <div class="qrcode-popup-title">
          <img class="icon" v-if="selectedObj.Paytype == 1" src="../../assets/images/alipay-icon.png" />
           <img class="icon" v-if="selectedObj.Paytype == 2" src="../../assets/images/qq-icon.png" />
          <img class="icon" v-if="selectedObj.Paytype == 3" src="../../assets/images/wechat-icon.png" />
          {{ selectedObj.Paytype == 1 ? '支付宝扫码支付' : '' }}
          {{ selectedObj.Paytype == 2 ? 'QQ扫码支付' : '' }}
          {{ selectedObj.Paytype == 3 ? '微信扫码支付' : '' }}
        </div>
      </template>
      <div class="qrcode-popup-content">
        <div class="money">¥ <span>{{ sdPayPopup.money }}</span></div>
        <div class="img img-contain" ref="qrcode"><img :src="sdPayPopup.img" /></div>
        <div class="title">识别码(点击识别码复制)</div>
        <div class="code" @click="handleCopy(sdPayPopup.remark)">{{ sdPayPopup.remark }}</div>
        <div class="tip">请使用{{ selectedObj.Paytype == 1 ? '支付宝' : '' }}{{ selectedObj.Paytype == 2 ? 'QQ' : '' }}{{ selectedObj.Paytype == 3 ? '微信' : '' }}扫描二维码支付</div>
      </div>
    </el-dialog>
    <el-dialog title="充值成功" class="pc-dialog" :visible.sync="showSuccess" width="520px" destroy-on-close @closed="showSuccess = false">
      <div class="success-popup">
        <div class="icon img-cover"><img src="../../assets/images/success-icon.png"></div>
        <div class="title">充值成功</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2'
import { mapState, mapActions } from 'vuex'
import pcPublicHeader from '@/components/pcPublicHeader'
import pcBalanceHeader from '@/components/pcBalanceHeader'

export default {
  name: "Balance",
  components: {
    pcPublicHeader,
    pcBalanceHeader
  },
  data() {
    return {
      showKm: false,
      czTip: '',
      czTip2: '',
      submiting: false,
      money: '',
      paymentList: [],
      selectedObj: {},
      dmfTimer: null,
      showQRCode: false,
      sdPayPopup: {
        show: false,
        img: '',
        remark: '',
        money: ''
      },
      showSuccess: false
    }
  },
  computed: {
    ...mapState(['userInfo', 'websiteInfo'])
  },
  filters: {
    
  },
  mounted() {
    this.getSetting()
    this.handleGetPayment()
  },
  methods: {
    ...mapActions(['GetUserInfo']),
    handleCopy(text) {
      this.$copyText(`${text}`).then(() => {
        this.$toast('复制成功')
      }).catch(() => {
        this.$toast('复制失败')
      })
    },
    handleChoosePayment(item) {
      this.selectedObj = item
    },
    async getSetting(){
      var res = await this.$API.getSets.post({
        Key: 'cz_remind,is_km,cz_remind2'
      })
      this.czTip = res.cz_remind
      this.czTip2 = res.cz_remind2
      this.showKm = res.is_km == 1
    },
    async handleGetPayment() {
      const { userInfo } = this
      var res = await this.$API.payment.post()
      if (res.info && res.info.length > 0) {
        const list = []
        for (let index = 0; index < res.info.length; index++) {
          const item = res.info[index];
          const isLevel = item.IsLevel ? item.IsLevel.split(',') : []
          if (isLevel.length == 0 || isLevel.includes(`${userInfo.Level}`)) {
            list.push(item)
          }
        }
        if (list.length > 0) {
          this.paymentList = list
          this.selectedObj = list[0]
        }
      }
    },
    async handleSubmit() {
      const { money, selectedObj, submiting } = this
      if (submiting) {
        return
      }
      if (!money && selectedObj.Id == -1) {
        this.$toast('请输入充值卡密')
        return
      }
      if (!money) {
        this.$toast('请输入充值金额')
        return
      }
      if (parseFloat(money) > parseFloat(selectedObj.Max)) {
        this.$toast('充值金额最高' + selectedObj.Max)
        return
      }
      if (parseFloat(money) < parseFloat(selectedObj.Min)) {
        this.$toast('充值金额最低' + selectedObj.Min)
        return
      }
      const params = {}
      let remark = ''
      if (selectedObj.Id == -1) {
        params.Code = money
        params.Switch = 1
      } else {
        params.Money = money
        params.PaymentId = selectedObj.Id
        params.PayType = selectedObj.Paytype
        if (selectedObj.Type == 1) {
          params.Switch = 2
        }
        if (selectedObj.Type == 2) {
          params.Switch = 3
          remark = this.$TOOL.generateRandomNumber(8)
          params.Remark = remark
        }
        if (selectedObj.Type == 3 || selectedObj.Type == 4) {
          params.Switch = 4
        }
        if (selectedObj.Type == 5) {
          params.Switch = 5
        }
      }
      this.submiting = true
      var res = await this.$API.pay.post(params)
      this.submiting = false
      if (res.error == 0) {
        if (selectedObj.Type == 3 || selectedObj.Type == 1) {
          window.location.href = res.info
          // window.open(res.info)
        } else if (selectedObj.Type == 5 || selectedObj.Type == 4) {
          this.handleSetPopup(res.info, res.orderNo || '')
        } else if (selectedObj.Type == 2) {
          this.sdPayPopup = {
            show: true,
            img: selectedObj.Vendorid,
            remark,
            money
          }
        } else {
          this.showSuccess = true
          this.GetUserInfo()
        }
      } else {
        this.$dialog.alert({
          type: 'danger',
          className: 'pc-dialog',
          title: '提示',
          message: res.info,
          confirmButtonText: '确定',
        })
      }
    },
    handleSetPopup(url, OrderNo) {
      this.showQRCode = true
      setTimeout(() => {
        this.$refs.qrcode.innerHTML = ''
        new QRCode(this.$refs.qrcode, {
          text: url,
          width: 260,
          height: 260,
          colorDark: '#000000',
          colorLight: '#ffffff',
          correctLevel: QRCode.CorrectLevel.H
        })
      }, 1000);
      if (OrderNo) {
        if (this.dmfTimer) {
          clearInterval(this.dmfTimer)
        }
        this.dmfTimer = setInterval(async () => {
          var res = await this.$API.reOrder.post({
            OrderNo: OrderNo
          })
          if (res.info.Status != 2) {
            this.handleClosePopup()
            this.showSuccess = true
            // this.$router.push({path: '/success'})
          }
        }, 1000)
      }
    },
    handleCloseSdPayPopup() {
      this.sdPayPopup.show = false
      this.GetUserInfo()
      this.$dialog.alert({
        type: 'danger',
        className: 'pc-dialog',
        title: '提示',
        message: '确认成功，请及时联系客服充值',
        confirmButtonText: '确定',
      })
    },
    handleClosePopup() {
      this.showQRCode = false
      this.GetUserInfo()
      if (this.dmfTimer) {
        clearInterval(this.dmfTimer)
        this.dmfTimer = null
      }
    }
  }
};
</script>

<style lang="less" scoped>
.page-title {
  padding: 30px 0 22px;
  line-height: 48px;
  font-size: 32px;
  font-weight: bold;
  color: #3d3d42;
}
.content-item {
  background-color: #fff;
  border-radius: 0 0 8px 8px;
  padding: 0 30px 108px;
  margin-bottom: 30px;
  .balance-item {
    padding: 36px 0;
    font-size: 18px;
    font-weight: 500;
    color: #3d3d42;
    line-height: 28px;
    border-bottom: 1px solid #e9edf5;
    span {
      margin-left: 32px;
      font-weight: bold;
      font-size: 22px;
    }
  }
  .title-item {
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 18px;
    color: #3d3d42;
    line-height: 27px;
  }
  .recharge-item {
    padding-top: 30px;
    padding-bottom: 10px;
    border-bottom: 1px solid #e9edf5;
    margin-bottom: 30px;
    .input-item {
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      margin-bottom: 30px;
      .input {
        width: 460px;
        height: 48px;
        box-sizing: border-box;
        border: 2px solid rgba(106, 95, 254, .12);
        border-radius: 6px;
        padding: 0 16px;
      }
      .tip {
        line-height: 18px;
        margin-left: 14px;
        color: #3d3d42;
        font-size: 14px;
      }
    }
    .cz-tip {
      margin-top: -20px;
      margin-bottom: 30px;
      white-space: pre-wrap;
      font-size: 14px;
      color: #fe346e;
      line-height: 18px;
    }
    .type-list-item {
      overflow: hidden;
      .item {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 12px;
        min-width: 126px;
        height: 48px;
        border: 2px solid rgba(106, 95, 254, .12);
        border-radius: 6px;
        margin-right: 20px;
        margin-bottom: 20px;
        float: left;
        &.active {
          border-color: rgba(106, 95, 254, 1);
          background-color: #fafbff;
        }
        .icon {
          width: 24px;
          height: 24px;
          margin-right: 8px;
        }
        .name {
          color: #0b212c;
        }
      }
    }
  }
  .order-item {
    margin-bottom: 40px;
    border-bottom: 1px solid #e9edf5;
    .item {
      margin-bottom: 20px;
      font-weight: 500;
      font-size: 16px;
      color: #9fafc1;
      span {
        color: #3d3d42;
      }
    }
  }
  .btn-item {
    .cz-tip {
      white-space: pre-wrap;
      font-size: 14px;
      color: #fe346e;
      line-height: 18px;
    }
    .btn {
      cursor: pointer;
      margin-top: 12px;
      width: 220px;
      height: 48px;
      text-align: center;
      line-height: 48px;
      color: #fff;
      font-weight: bold;
      font-size: 14px;
      border-radius: 6px;
      background: linear-gradient( 90deg, #7683ff 0%, #6a5ffe 100%);
    }
  }
}
.qrcode-popup-title {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #333;
  .icon {
    width: 32px;
    height: 32px;
    margin-right: 12px;
  }
}
.qrcode-popup-content {
  padding: 30px;
  .img {
    width: 260px;
    height: 260px;
    margin: 0 auto 30px;
  }
  .title,
  .tip {
    text-align: center;
    font-size: 18px;
    color: #9fafc1;
    line-height: 28px;
  }
  .money {
    text-align: center;
    line-height: 44px;
    color: #333;
    font-weight: bold;
    font-size: 40px;
    margin-bottom: 20px;
  }
  .code {
    margin-bottom: 30px;
    text-align: center;
    line-height: 34px;
    color: #333;
    font-weight: bold;
    font-size: 30px;
    cursor: pointer;
  }
}
.success-popup {
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .icon {
    width: 140px;
    height: 140px;
  }
  .title {
    margin-top: 30px;
    text-align: center;
    color: #333;
    font-size: 30px;
  }
}
</style>
