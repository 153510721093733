<template>
  <div class="public-user-header-container">
    <router-link class="item" :class="pageKey == 'balance' ? 'active' : ''" :to="{ path: '/balance' }">充值</router-link>
    <router-link class="item" :class="pageKey == 'rechargeRecord' ? 'active' : ''" :to="{ path: '/rechargeRecord' }">充值记录</router-link>
  </div>
</template>

<script>

export default {
  name: 'PcBalanceHeader',
  props: {
    pageKey: String
  },
  data() {
    return {
      
    }
  },
  mounted() {

  },
  methods: {
    
  }
}
</script>

<style scoped lang="less">
.public-user-header-container {
  width: 100%;
  height: 64px;
  border-radius: 8px 8px 0 0;
  background-color: #fafbff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .item {
    cursor: pointer;
    width: 124px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px 8px 0 0;
    margin-right: 30px;
    font-size: 16px;
    color: #3d3d42;
    &.active,
    &:hover {
      font-weight: bold;
      color: #6a5ffe;
      background-color: #fff;
      box-shadow: 4px -4px 12px 0 rgba(0, 0, 0, .06);
    }
  }
}
</style>
